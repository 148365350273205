<template>
  <div :style="styleWidth">
    <div>
      <label class="primary--text">{{ label }}</label>
    </div>
    <v-textarea
      v-bind="$attrs"
      v-model="value"
      :name="name"
      :placeholder="placeholder + (facultatif ? ' (Facultatif)' : '')"
      :disabled="disabled"
      :hint="hint"
      :suffix="suffix"
      :readonly="readonly"
      bg-color="none"
      auto-grow
      :error-messages="errorMessage"
      variant="outlined"
      rounded="lg"
      class="copilot-input"
      @input="$emit('emitValue', textValue)"
      @blur="$emit('emitBlur', textValue)"
    />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  value?: string;
  label?: string;
  width?: string;
  name?: string;
  validationRule?: string;
  errorMessages?: string[];
  disabled?: boolean;
  hint?: string;
  placeholder?: string;
  suffix?: string;
  readonly?: boolean;
  facultatif?: boolean;
}>();

const textValue = ref(props.value ? props.value : '');

const {value, errorMessage} = useField(() => props.name);

const styleWidth = computed(() => {
  return `width: ${props.width}px;`;
});
</script>
<style scoped>
.copilot-input {
  border-radius: 10px !important;
}
</style>
<style>
textarea::placeholder {
  font-size: 13px !important;
}
</style>
